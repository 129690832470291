import { FooterLink } from './FooterLink'

export const Footer = (props) => {
    return (
        <div className="flex font-opensans justify-between flex-wrap">
            <div className="flex flex-col">
                <h3 className="text-xl font-bold pb-2">LOCATIONS</h3>
                <FooterLink url="https://kingdomcity.com/online" name="Online Campus" />
                <FooterLink url="https://kingdomcity.com/kualalumpur" name="Kuala Lumpur" />
                <FooterLink url="https://kingdomcity.com/perth" name="Perth" />
                <FooterLink url="https://kingdomcity.com/hobart" name="Hobart" />
                <FooterLink url="https://kingdomcity.com/penang" name="Penang" />
                <FooterLink url="https://kingdomcity.com/ipoh" name="Ipoh "/>
                <FooterLink url="https://kingdomcity.com/johorbahru" name="Johor Bahru" />
                <FooterLink url="https://kingdomcity.com/kuching" name="Kuching" />
                <FooterLink url="https://kingdomcity.com/kotakinabalu" name="Kota Kinabalu" />
                <FooterLink url="https://kingdomcity.com/melaka" name="Melaka" />
                <FooterLink url="https://kingdomcity.com/cambodia" name="Cambodia" />
                <FooterLink url="https://kingdomcity.com/singapore" name="Singapore" />
                <FooterLink url="https://kingdomcity.com/botswana" name="Botswana" />
                <FooterLink url="https://kingdomcity.com/dubai" name="Dubai" />
                <FooterLink url="https://kingdomcity.com/london" name="London" />
                <FooterLink url="https://kingdomcity.com/bali" name="Bali" />
                <FooterLink url="https://kingdomcity.com/surabaya" name="Surabaya" />
                <FooterLink url="https://kingdomcity.com/zambia" name="Zambia" />
                <FooterLink url="https://kingdomcity.com/srilanka" name="Sri Lanka" />
                <FooterLink url="https://kingdomcity.com/india" name="India" />
                <FooterLink url="https://kingdomcity.com/johannesburg" name="Johannesburg" />
                <FooterLink url="https://kingdomcity.com/auckland" name="Auckland" />
                <FooterLink url="https://kingdomcity.com/mexico" name="Mexico" />
            </div>
            <div>
                <h3 className="text-xl font-bold pb-2">ABOUT</h3>
                <FooterLink url="https://kingdomcity.com/about" name="Our Team" />
                <FooterLink url="https://kingdomcity.com/ourstory" name="Our Story" />
                <FooterLink url="https://kingdomcity.com/webelieve" name="We Believe" />
                <h3 className="text-xl font-bold pb-2 pt-5">NEW CHRISTIANS</h3>
                <FooterLink url="https://kingdomcity.com/jesus" name="About Jesus" />
                <FooterLink url="https://kingdomcity.com/jesus/newtochrist" name="New Christian Resources" />
                <h3 className="text-xl font-bold pb-2 pt-5">GET CONNECTED</h3>
                <FooterLink url="https://kingdomcity.com/connect" name="Join a Connect Group" />
                <FooterLink url="https://kingdomcity.com/connectportal" name="Connect Portal" />
                <h3 className="text-xl font-bold pb-2 pt-5">GENERATIONS</h3>
                <FooterLink url="https://kingdomcity.com/kids" name="Kingdomcity Kids" />
                <FooterLink url="https://kingdomcity.com/kids/activities" name="Kids Activities" />
                <FooterLink url="https://kingdomcity.com/youth" name="Kingdomcity Youth" />
            </div>
            <div>
                <h3 className="text-xl font-bold pb-2">MEDIA</h3>
                <FooterLink url="https://kingdomcity.com/music" name="Kingdomcity Music" />
                <FooterLink url="https://kingdomcity.com/readyfireaim" name="Ready Fire! Aim" />
                <FooterLink url="https://kingdomcity.com/store" name="Kingdomcity Store" />
                <h3 className="text-xl font-bold pb-2 pt-5">FOLLOW US</h3>
                <FooterLink url="http://youtube.com/kingdomcity" name="Youtube" />
                <FooterLink url="http://facebook.com/kingdomcity" name="Facebook" />
                <FooterLink url="http://instagram.com/kingdomcity" name="Instagram" />
                <FooterLink url="http://twitter.com/kingdomcity" name="Twitter" />
                <FooterLink url="https://www.pinterest.com/kingdomcity/" name="Pinterest" />
                <FooterLink url="https://open.spotify.com/artist/4syoo7XFyGlEBWp88G5rHM?si=NeKTzFmPRpGloHJdBGwMDA&dl_branch=1" name="Spotify" />
                <FooterLink url="https://music.apple.com/us/artist/kingdomcity/1221491407" name="Apple Music" />
                <FooterLink url="https://www.multitracks.com/artists/Kingdomcity/" name="Multitracks" />
                <h3 className="text-xl font-bold pb-2 pt-5">GIVE ONLINE</h3>
                <FooterLink url="https://kingdomcity.com/give" name="Give Securely" />
                <h3 className="text-xl font-bold pb-2 pt-5">PRAYER & PRAISE</h3>
                <FooterLink url="https://kingdomcity.com/pray" name="Prayer Request" />
                <FooterLink url="https://kingdomcity.com/pray" name="Praise Report" />
            </div>
            <div>
                <h3 className="text-xl font-bold pb-2">DISCIPLESHIP</h3>
                <FooterLink url="https://kingdomcity.com/greenhouse" name="Greenhouse" />
                <FooterLink url="https://kingdomcity.com/greenroom" name="Greenroom" />
                <FooterLink url="https://kingdomcity.com/school" name="Kingdomcity School" />
                <h3 className="text-xl font-bold pb-2 pt-5">EVENTS</h3>
                <FooterLink url="https://kingdomcity.com/gathering" name="Gathering" />
                <FooterLink url="https://kingdomcity.com/miracleoffering" name="Miracle Offering" />
                <FooterLink url="https://kingdomcity.com/easter" name="Easter at Kingdomcity" />
                <FooterLink url="https://kingdomcity.com/vision" name="Vision Sunday" />
                <FooterLink url="https://kingdomcity.com/christmas" name="Christmas at Kingdomcity" />
                <FooterLink url="https://kingdomcity.com/thisiskingdomcity" name="This is Kingdomcity" />
                <FooterLink url="https://kingdomcity.com/roundtable" name="Pastors Roundtable" />
                <FooterLink url="https://kingdomcity.com/amazing" name="Kids Amazing Day" />
                <FooterLink url="https://kingdomcity.com/live" name="Kingdomcity Live" />
                <a href="https://kingdomcity.com" target="_blank"><img className="my-1 w-[132.52px] max-w-full" src={process.env.PUBLIC_URL+"kclogo.png"}/></a>
                <p className="pb-1">Copyright Kingdomcity 2024</p>
                <FooterLink url="https://kingdomcity.com/privacy" name="Privacy Notice" />
            </div>
        </div>
    );
}
